import {
  Box,
  Text,
  useBreakpointValue,
  Heading,
  Flex,
  Image,
  Button,
  Grid,
  Badge,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { MarketplaceService } from "@/schemaTypes";
import { PageContainer } from "@/components/PageContainer";
import { noCacheHeaders } from "@/utils/headers";
import { useEffect, useState } from "react";
import { Ico } from "@/assets/icons";
import { useParams } from "react-router-dom";
import { GetServiceForIdDocument } from "./graphql/getSingleServiceById.generated";
import { BigSpinner } from "@/components/BigSpinner";
import { AppRoute } from "@/AppRoute";

const ServicesMarketplaceDetailPage: React.FC = () => {
  const [currentService, setCurrentService] = useState<
    MarketplaceService | null | undefined
  >(undefined);
  const { serviceId } = useParams();
  const [serviceDetail, { data: service, loading }] = useLazyQuery(
    GetServiceForIdDocument,
    {
      variables: {
        serviceId: serviceId ?? "",
      },
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );
  useEffect(() => {
    serviceDetail();
  }, [serviceDetail]);

  useEffect(() => {
    if (service?.getServiceForId) {
      setCurrentService(service?.getServiceForId);
    }
  }, [service]);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const handleContactMeClick = () => {
    window.open(currentService?.contactLink ?? "", "_blank");
  };
  const handleHelpClick = () => {
    window.open("https://google.com", "_blank");
  };
  return (
    <PageContainer goBackTo={AppRoute.ServicesMarketplace}>
      {!loading ? (
        <Flex direction={"row"} gap={"48px"}>
          <Flex
            w={isMobile ? "100%" : "60%"}
            minW={isMobile ? undefined : "680px"}
            direction={"column"}
          >
            <Flex mb={"24px"} alignItems={"center"} justifyContent={"start"}>
              <Heading as={"h4"}>
                <Trans>{currentService?.title}</Trans>
              </Heading>
            </Flex>
            <Flex direction={"row"} gap={"16px"}>
              <Box
                borderRadius={"250px"}
                border={"1px solid"}
                borderColor={"neutral.100"}
                p={"4px"}
                py={"6px"}
                w={"64px"}
                h={"64px"}
                overflow={"hidden"}
                textAlign={"center"}
                alignItems={"flex-start"}
              >
                <Image
                  w={"50px"}
                  maxW={"50px"}
                  h={"50px"}
                  maxH={"50px"}
                  mx={"auto"}
                  src={
                    currentService?.agencyImage &&
                    currentService?.agencyImage !== ""
                      ? currentService?.agencyImage
                      : "/img/logo_smartbeemo.svg"
                  }
                ></Image>
              </Box>
              <Box mx={2} mb={2}>
                <Heading as={"p"}>{currentService?.agency}</Heading>
                {[currentService?.category, currentService?.subcategory].map(
                  (category) => {
                    return category?.id ? (
                      <Badge
                        key={currentService?.id + "-" + category?.id}
                        px={"8px"}
                        py={"2px"}
                        bg={"neutral.50"}
                        textTransform={"capitalize"}
                        w={"fit-content"}
                        color={"neutral.500"}
                        borderRadius={"6px"}
                        mr={"8px"}
                      >
                        <Text fontSize={"12px"}>{category?.name}</Text>
                      </Badge>
                    ) : null;
                  }
                )}
                <Heading mt={2} as={"p"} fontSize={"14px"}>
                  {currentService?.subtitle}
                </Heading>
              </Box>
            </Flex>
            <Text fontSize={"14px"} lineHeight={"22.4px"}>
              {currentService?.description}
            </Text>
            <Heading mt={"32px"} as={"h6"}>
              ¿Por qué nosotros?
            </Heading>
            <Text fontSize={"14px"} mt={"8px"}>
              {currentService?.whyUs ?? "No hay informacion para mostrar"}
            </Text>
            <Heading mt={"32px"} as={"h6"}>
              ¿Qué servicios ofrecemos?
            </Heading>
            <Grid
              templateColumns={"repeat(auto-fit, minmax(min-content, 200px))"}
              fontSize={"14px"}
              gap={"16px"}
              my={"16px"}
              display={"grid"}
              flexWrap={"nowrap"}
            >
              {currentService?.services &&
              currentService?.services.length > 0 ? (
                currentService?.services.map((_) => {
                  return (
                    <Flex
                      direction={"row"}
                      alignItems={"center"}
                      gap={"4px"}
                      w={"max-content"}
                      key={_}
                    >
                      <Ico.Check fontSize={"16px"} fill={"#427AFF"}></Ico.Check>
                      <Text fontSize={"14px"}>{_}</Text>
                    </Flex>
                  );
                })
              ) : (
                <Text>No hay informacion para mostrar</Text>
              )}
            </Grid>
            <Heading mt={"32px"} as={"h6"} mb={"8px"}>
              ¿Cómo funciona?
            </Heading>
            <OrderedList type="1">
              {currentService?.howItWorks &&
              currentService?.howItWorks.length > 0 ? (
                currentService?.howItWorks.map((_) => {
                  return (
                    <ListItem key={_}>
                      <Text fontSize={"14px"}>{_}</Text>
                    </ListItem>
                  );
                })
              ) : (
                <Text>No hay informacion para mostrar</Text>
              )}
            </OrderedList>
          </Flex>
          <Flex
            px={"24px"}
            py={"16px"}
            w={isMobile ? "100%" : "40%"}
            minH={"190px"}
            maxH={"260px"}
            minW={isMobile ? undefined : "352px"}
            direction={"column"}
            justifyContent={"center"}
            textAlign={"center"}
            rounded={"5px"}
            border={"1px solid"}
            borderColor={"neutral.100"}
          >
            <Flex
              direction={"row"}
              gap={"16px"}
              alignItems={"center"}
              w={"100%"}
            >
              <Box
                borderRadius={"250px"}
                border={"1px solid"}
                borderColor={"neutral.100"}
                p={"4px"}
                w={"50px"}
                h={"50px"}
                mb={"16px"}
                overflow={"hidden"}
              >
                <Image
                  w={"100%"}
                  maxW={"40px"}
                  h={"40px"}
                  maxH={"40px"}
                  mx={"auto"}
                  src={
                    currentService?.agencyImage &&
                    currentService?.agencyImage !== ""
                      ? currentService?.agencyImage
                      : "/img/logo_smartbeemo.svg"
                  }
                ></Image>
              </Box>
              <Box mx={2} mb={2}>
                <Heading as={"p"}>{currentService?.agency}</Heading>
              </Box>
            </Flex>
            <Flex
              pt={"4px"}
              direction={"row"}
              align={"center"}
              textAlign={"center"}
              justifyContent={"center"}
              gap={1}
            >
              <Ico.Clock fontSize={"18px"} color={"#69696C"}></Ico.Clock>
              <Text color={"secondary.100"} fontSize={"14px"}>
                Tiempo de respuesta: 24 horas
              </Text>
            </Flex>
            <Button
              mt={"32px"}
              mb={"16px"}
              variant={"primary"}
              onClick={handleContactMeClick}
            >
              <Text mr={2} fontWeight={"500"} fontSize={"14px"}>
                Contáctame
              </Text>
              <Ico.PaperPlane fontSize={"18px"}></Ico.PaperPlane>
            </Button>
            <Button variant={"link"} onClick={handleHelpClick}>
              <Text mr={2} fontWeight={"500"} fontSize={"14px"} py={"8px"}>
                Necesito ayuda
              </Text>
            </Button>
          </Flex>
        </Flex>
      ) : (
        <BigSpinner></BigSpinner>
      )}
    </PageContainer>
  );
};

export default ServicesMarketplaceDetailPage;
