import {
  Box,
  Text,
  useBreakpointValue,
  Heading,
  Flex,
  Input,
  Button,
  InputGroup,
  InputLeftElement,
  TabList,
  Tabs,
  Tab,
  TabIndicator,
  Grid,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { Category, MarketplaceService } from "@/schemaTypes";
import { PageContainer } from "@/components/PageContainer";
import { GetServicesForDisplayDocument } from "./graphql/getServicesMarkteplace.generated";
import { noCacheHeaders } from "@/utils/headers";
import { useEffect, useState } from "react";
import { Ico } from "@/assets/icons";
import { Chips } from "@/components/Forms";
import { ServiceCard } from "./components/ServiceCard";
import { ServiceMarketplaceCardFragment } from "./graphql/serviceMarketplaceFragment.generated";

const ServicesMarketplacePage: React.FC = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [servicesQuery, { data: services }] = useLazyQuery(
    GetServicesForDisplayDocument,
    {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );
  const [servicesForView, setServicesForView] = useState<
    Array<MarketplaceService | null>
  >([]);
  const [filteredServicesForView, setFilteredServicesForView] = useState<
    Array<MarketplaceService | null> | undefined
  >(undefined);
  const [categoriesForView, setCategoriesForView] = useState<Array<Category>>(
    []
  );
  const [subCategoriesForView, setSubCategoriesForView] = useState<
    Array<Category>
  >([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState<string>("");
  const [selectedSubCategories, setSelectedSubCategories] = useState<
    Array<string>
  >([]);
  const [searchServicesForView, setSearchServicesForView] = useState<
    Array<MarketplaceService | null> | undefined
  >(undefined);
  const [selectedIndex, setSelectedIndex] = useState(0); // Default to the first tab

  const setFilteredServices = () => {
    const allSubCategoriesSelected =
      selectedSubCategories.length === 1 && selectedSubCategories[0] === "ALL";
    let rawData = searchServicesForView ?? servicesForView;
    if (selectedMainCategory === "ALL" && allSubCategoriesSelected) {
      if (searchServicesForView) {
        setFilteredServicesForView(rawData);
      } else {
        setFilteredServicesForView(undefined);
      }
    } else {
      if (selectedMainCategory !== "ALL") {
        rawData = rawData.filter(
          (item) => item?.categoryId === selectedMainCategory
        );
      }
      if (allSubCategoriesSelected) {
        rawData = rawData.filter((item) =>
          subCategoriesForView
            .flatMap((_) => _.id)
            .includes(item?.subcategoryId as string)
        );
      } else {
        rawData = rawData.filter((item) =>
          selectedSubCategories.includes(item?.subcategoryId as string)
        );
      }
      setFilteredServicesForView(rawData);
    }
  };
  useEffect(() => {
    servicesQuery();
  }, [servicesQuery]);

  useEffect(() => {
    if (
      services?.getServicesForDisplay &&
      services.getServicesForDisplay.length > 0
    ) {
      setServicesForView(services.getServicesForDisplay);
      const categories = services.getServicesForDisplay.flatMap((_) => {
        return _?.category as Category;
      });
      const uniqueCategories = categories.filter(
        (item, index, self) =>
          index === self.findIndex((cat) => cat.id === item.id)
      );
      const subcategories = services.getServicesForDisplay.flatMap((_) => {
        return _?.subcategory as Category;
      });
      const uniqueSubcategories = subcategories.filter(
        (item, index, self) =>
          index === self.findIndex((sub) => sub.id === item.id)
      );
      uniqueCategories.unshift({ id: "ALL", name: "Todas" } as Category);
      uniqueSubcategories.unshift({ id: "ALL", name: "Todas" } as Category);
      setCategoriesForView(uniqueCategories);
      setSubCategoriesForView(uniqueSubcategories);
      setSelectedMainCategory("ALL");
      setSelectedSubCategories(["ALL"]);
    }
  }, [services]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    if (searchTerm && searchTerm !== "") {
      const lowerSearchString = searchTerm.toLowerCase();
      const searchedData = servicesForView.filter(
        (srvc) =>
          srvc?.agency?.toLowerCase().includes(lowerSearchString) ||
          srvc?.title?.toLowerCase().includes(lowerSearchString) ||
          srvc?.description?.toLowerCase().includes(lowerSearchString) ||
          srvc?.subtitle?.toLowerCase().includes(lowerSearchString)
      );
      setSearchServicesForView(searchedData);
      setSelectedMainCategory("ALL");
      setSelectedSubCategories(["ALL"]);
      setFilteredServices();
    }
  };

  const handleCleanSearch = () => {
    setSearchServicesForView(undefined);
    setSearchTerm("");
    setSelectedMainCategory("ALL");
    setSelectedSubCategories(["ALL"]);
  };

  const selectMainCategory = (categoryId: string, index: number) => {
    setSelectedMainCategory(categoryId);
    setSelectedIndex(index);
  };
  const selectSubCategory = (categoryIds: Array<string>) => {
    if (categoryIds.indexOf("ALL") > 0) {
      categoryIds = ["ALL"];
    } else {
      if (
        categoryIds.length > 1 &&
        categoryIds.length < subCategoriesForView.length &&
        categoryIds.includes("ALL")
      ) {
        categoryIds = categoryIds.filter((category) => category !== "ALL");
      } else if (
        categoryIds.length === 0 ||
        (categoryIds.length === 1 && categoryIds.includes("ALL"))
      ) {
        categoryIds = ["ALL"];
      }
    }
    setSelectedSubCategories(categoryIds);
  };

  useEffect(() => {
    if (selectedMainCategory && selectedMainCategory !== "") {
      setFilteredServices();
    }
  }, [selectedMainCategory]);
  useEffect(() => {
    setFilteredServices();
  }, [selectedSubCategories]);

  useEffect(() => {
    if (searchServicesForView && searchServicesForView.length > 0) {
      setFilteredServices();
    }
  }, [searchServicesForView]);

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <PageContainer title={"Agencias"}>
      <Heading as={"h4"} mb={"24px"}>
        <Trans>Servicios profesionales para tu Ecommerce.</Trans>
      </Heading>
      <Flex mb={"24px"} alignItems={"center"} justifyContent={"start"}>
        <InputGroup mr={4} maxWidth="512px" w={"100%"} h={"40px"}>
          <InputLeftElement pointerEvents="none">
            <Ico.MagnifyingGlass></Ico.MagnifyingGlass>
          </InputLeftElement>
          <Input
            fontSize={"14px"}
            _placeholder={"font-size: 14px"}
            placeholder={t("Buscar")}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </InputGroup>

        <Button
          h={"40px"}
          fontSize={"14px"}
          variant={"primary"}
          onClick={handleSearchClick}
        >
          <Trans>Buscar</Trans>
        </Button>
        <Button
          mx={2}
          h={"40px"}
          fontSize={"14px"}
          variant={"secondary"}
          onClick={handleCleanSearch}
        >
          <Trans>Limpiar búsqueda</Trans>
        </Button>
      </Flex>
      <Flex mb={"24px"}>
        {categoriesForView && categoriesForView.length > 0 ? (
          <Tabs index={selectedIndex} onChange={setSelectedIndex}>
            <TabList
              pl={0}
              ml={{ base: "16px", lg: 0 }}
              color={"neutral.300"}
              maxW={"max-content"}
            >
              {categoriesForView.map((tab, index) => (
                <Tab
                  key={tab.id}
                  _selected={{ color: "secondary.300" }}
                  _active={{ color: "secondary.300" }}
                  pt={0}
                  onClick={() => {
                    selectMainCategory(tab.id, index);
                  }}
                >
                  <Text variant={"caption"}>{tab.name}</Text>
                </Tab>
              ))}
            </TabList>
            <TabIndicator
              mt="-2px"
              height="2px"
              bg="secondary.300"
              borderRadius="8px"
            />
          </Tabs>
        ) : null}
      </Flex>
      <Flex mb={"32px"}>
        {subCategoriesForView ? (
          <Chips
            onChange={(values) => {
              selectSubCategory(values);
            }}
            options={subCategoriesForView.map((category) => ({
              name: category.name,
              value: category.id,
            }))}
            values={selectedSubCategories}
            singleSelect={false}
          />
        ) : null}
      </Flex>
      {servicesForView &&
      servicesForView.length > 0 &&
      !filteredServicesForView ? (
        <Grid
          gap="16px"
          templateColumns={
            isMobile ? "1" : "repeat(auto-fit, minmax(319px, min-content))"
          }
          justifyItems="start"
          alignSelf="flex-start"
          justifyContent="flex-start"
          my="1"
          w="100%"
          display={isMobile ? "auto" : "grid"}
        >
          {servicesForView.map((service) => (
            <ServiceCard
              service={service as ServiceMarketplaceCardFragment}
              key={service?.id}
            ></ServiceCard>
          ))}
        </Grid>
      ) : filteredServicesForView && filteredServicesForView.length > 0 ? (
        <Grid
          gap="16px"
          templateColumns={
            isMobile ? "1" : "repeat(auto-fit, minmax(319px, min-content))"
          }
          justifyItems="start"
          alignSelf="flex-start"
          justifyContent="flex-start"
          my="1"
          w="100%"
          display={isMobile ? "auto" : "grid"}
        >
          {filteredServicesForView.map((service) => (
            <ServiceCard
              key={service?.id}
              service={service as ServiceMarketplaceCardFragment}
            ></ServiceCard>
          ))}
        </Grid>
      ) : (
        <Box>
          <Text>No se encontraron Agencias para la categoria seleccionada</Text>
        </Box>
      )}
    </PageContainer>
  );
};

export default ServicesMarketplacePage;
